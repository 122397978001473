import * as React from 'react'
import { Link } from '@reach/router'
import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <section id="contact-us">
      <div className="container">
        <div className="cta">
          <Seo title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39; t exist...the sadness.</p>
          <Link to="/" title="Homepage"><button>Homepage</button></Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
